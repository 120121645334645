<div class="registered-address-container">
  <div class="header-container">
    <div class="header">
      <p>REGISTERED ADDRESS</p>
    </div>
  </div>
  <form [formGroup]="formGroup">
    <div class="form" formGroupName="registeredAddress">
      <mat-form-field class="lane">
        <label>
          <input type="text" matInput formControlName="lane" placeholder="Lane" class="lane-value" />
        </label>
      </mat-form-field>

      <mat-form-field class="city">
        <label>
          <input type="text" matInput formControlName="city" placeholder="City" class="city-value" />
        </label>
      </mat-form-field>

      <mat-form-field class="country">
        <label>
          <input
            type="text"
            matInput
            formControlName="country"
            placeholder="Country*"
            [matAutocomplete]="countryAuto"
            class="country-value"
            (keyup)="searchCountry($event)"
            (click)="onClickCountry()" />
          <div>
            <i class="material-icons align-arrow-right">arrow_drop_down</i>
          </div>
        </label>
        <mat-autocomplete #countryAuto="matAutocomplete" (optionSelected)="onSelectCountry()">
          <mat-option class="select-label" *ngFor="let country of filteredCountriesList" [value]="country.countryName">
            {{ country.countryName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="state">
        <label>
          <input
            type="text"
            matInput
            formControlName="state"
            placeholder="State*"
            [matAutocomplete]="stateAuto"
            class="state-value"
            (keyup)="searchState($event)"
            (click)="onClickState()" />
          <div>
            <i class="material-icons align-arrow-right">arrow_drop_down</i>
          </div>
        </label>
        <mat-autocomplete #stateAuto="matAutocomplete" (optionSelected)="onSelectState()">
          <ng-container *ngFor="let country of countries">
            <ng-container *ngIf="country.countryName === formGroup.get('registeredAddress').get('country').value">
              <mat-option class="select-label" *ngFor="let state of filteredStatesList" [value]="state">
                {{ state }}
              </mat-option>
            </ng-container>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="zipCode">
        <label>
          <input type="text" matInput formControlName="zipCode" placeholder="Zip Code" class="zipCode-value" />
        </label>
      </mat-form-field>
    </div>
  </form>
</div>
