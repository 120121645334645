<div class="pagination" [ngClass]="{ disabled: disabled }">
  <button (click)="previousPage()" [disabled]="disabled || currentPage === 1">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span
    *ngFor="let page of pages"
    (click)="!disabled && setPage(page)"
    [ngClass]="{ active: currentPage === page, disabled: disabled }"
    role="presentation">
    {{ page }}
  </span>
  <button (click)="nextPage()" [disabled]="disabled || currentPage === totalPages">
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
