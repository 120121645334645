import { Component, Input, WritableSignal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LocationCountryDetails } from '@shared/models/org-country-details.model';
@Component({
  selector: 'hmt-registered-address',
  templateUrl: './registered-address.component.html',
  styleUrls: ['./registered-address.component.scss'],
})
export class RegisteredAddressComponent {
  filteredCountriesList: LocationCountryDetails[] = [];
  filteredStatesList: string[] = [];

  @Input() public formGroup: FormGroup;
  @Input() countries: LocationCountryDetails[];
  @Input() formValidity: WritableSignal<boolean>;

  onSelectCountry(): void {
    this.formGroup.controls['registeredAddress'].patchValue({ state: '' });
    this.filteredCountriesList = [];
    this.filteredStatesList = [];
    this.formValidity.set(false); //still state have to be set
  }

  onSelectState(): void {
    this.formValidity.set(true);
    this.filteredStatesList = [];
  }

  searchCountry(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const e = target.value;
    this.formValidity.set(false);
    const val = e.toLowerCase();
    this.filteredCountriesList = this.countries.filter(country => {
      // INFO: HERE -1 is used to check if the value is not found, so no need to use a constant
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (country.countryName.toLowerCase().indexOf(val) !== -1 || !val) {
        return country;
      } else {
        return false;
      }
    });
  }

  onClickCountry() {
    if (!this.formGroup.get(['registeredAddress', 'country']).value) {
      this.filteredCountriesList = this.countries;
    }
  }

  searchState(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const e = target.value;
    this.formValidity.set(false);
    if (!this.formGroup.get(['registeredAddress', 'country']).value) {
      return;
    }
    const val = e.toLowerCase();
    const statesOfSelectedCountry = this.countries.find(
      country => country.countryName === this.formGroup.get(['registeredAddress', 'country']).value
    )?.states;
    if (statesOfSelectedCountry) {
      this.filteredStatesList = statesOfSelectedCountry.filter(state => {
        // INFO: HERE -1 is used to check if the value is not found, so no need to use a constant
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (state.toLowerCase().indexOf(val) !== -1 || !val) {
          return state;
        } else {
          return false;
        }
      });
    }
  }

  onClickState() {
    if (this.formGroup.get(['registeredAddress', 'country']).value) {
      if (!this.formGroup.get(['registeredAddress', 'state']).value) {
        this.filteredStatesList = this.countries.find(
          country => country.countryName === this.formGroup.get(['registeredAddress', 'country']).value
        )?.states;
      }
    } else {
      this.filteredStatesList = [];
    }
  }
}
