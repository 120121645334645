import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hmt-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent {
  @Input() formGroup: FormGroup;
  @Output() addContact = new EventEmitter();
  @Output() removeContact = new EventEmitter<number>();

  constructor() {}

  deleteContact(val): void {
    this.removeContact.emit(val);
  }

  addNewContact() {
    this.addContact.emit();
  }
}
