@if (
  preSignedUrl() &&
  (preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_PDF ||
    preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_OCTET_STREAM ||
    preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_MSWORD ||
    preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_WORD ||
    preSignedUrl()?.contentType === fileTypeExtension.APPLICATION_DOX)
) {
  <ngx-doc-viewer
    class="document-viewer-pdf-doc"
    [url]="preSignedUrl()?.url"
    viewer="url"
    style="height: 100vh; width: 100%"></ngx-doc-viewer>
} @else if (
  preSignedUrl() &&
  (preSignedUrl()?.contentType === fileTypeExtension.IMAGE_JPEG ||
    preSignedUrl()?.contentType === fileTypeExtension.IMAGE_PNG ||
    preSignedUrl()?.contentType === fileTypeExtension.IMAGE_JPG)
) {
  <img class="document-viewer-png-jpeg" src="{{ preSignedUrl()?.url }}" [alt]="file().fileName" />
}
